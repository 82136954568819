import type { Reply, User } from '@/types/api.js'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', () => {
  const nuxtApp = useNuxtApp()
  const router = useRouter()
  const user = ref()

  const login = async (email: string, password: string) => {
    try {
      const response = await nuxtApp.$urlEfici.post<Reply<{ token: string }>>('users/login/', {
        email,
        password,
      }, { loader: true })
      if (response.data.error)
        throw new Error(response.data.message)

      const token = response.data.data.token
      localStorage.setItem('authToken', token)

      router.push('/')
      user.value = response.data.data
    }
    catch (e) {
      console.error((e as Error).message)
      return e
    }
  }

  const logout = () => {
    user.value = null
    localStorage.removeItem('user')
    localStorage.removeItem('authToken')
    localStorage.removeItem('DateToken')
  }

  const isAuthenticated = async () => {
    if (import.meta.client) {
      if (user.value)
        return true

      const token = localStorage.getItem('authToken') as string

      if (!token)
        return false

      try {
        const response = await nuxtApp.$urlEfici.get<Reply<User>>('users/getUser')
        if (response.data.error) {
          localStorage.removeItem('authToken')
          return false
        }

        user.value = response.data.data
        return true
      }
      catch (e) {
        console.error((e as Error).message)
        return e
      }
    }
  }

  return { user, login, logout, isAuthenticated }
})
